import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SignInData } from '../models/sign-in-data.model';
import { AppState } from '../state/app.state';
import { userSignIn } from '../state/auth/auth.actions';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { auto } from '@popperjs/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  fullpage: boolean = true;
  fullpage1: boolean = true;
  first: boolean = false;

  loginModal: NgbModalRef;
  registerModal: NgbModalRef;

  constructor(private store: Store<AppState>, private modalService: NgbModal, config: NgbCarouselConfig, private router:Router) {
    config.interval = 10000;
    config.wrap = true;
    config.keyboard = true;
    config.pauseOnHover = false;
  }
  /* images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);*/

  images = [
    "assets/era/Images/Carousel-image-01.png",
    "assets/era/Images/Carousel-image-02.png",
    "assets/era/Images/Carousel-image-03.png",
    "assets/era/Images/Carousel-image-04.png",
  ];

  images1 = [
    "assets/era/Images/Carousel-image-04.png",
    "assets/era/Images/Carousel-image-01.png",
    "assets/era/Images/Carousel-image-02.png",
    "assets/era/Images/Carousel-image-03.png",
  ];

  images2 = [
    "assets/era/Images/Carousel-image-03.png",
    "assets/era/Images/Carousel-image-01.png",
    "assets/era/Images/Carousel-image-02.png",
    "assets/era/Images/Carousel-image-04.png",
  ];

  images3 = [
    "assets/home/Home1.jpg",
    "assets/home/Home2.jpg",  
  ];

  images4 = [
    "assets/home/H1.jpg",
    "assets/home/H2.jpg",
    "assets/home/H3.jpg",
    "assets/home/H4.jpg",
    "assets/home/H5.png",
    "assets/home/H6.png",
    "assets/home/H7.jpg",
    "assets/home/H8.jpg",
    "assets/home/H9.jpg",
    
  ];

  images5 = [
    "assets/home/F1.jpg",
    "assets/home/F2.png",
    "assets/home/F3.png",
    "assets/home/F4.png",
    "assets/home/F5.jpg",
  ];

  ngOnInit() {
    window.scrollTo(0, 0);
    
  }

  openRegister(register) {
    this.registerModal = this.modalService.open(register, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  close_register() {
    this.registerModal.close();
  }

  openLogin(login) {
    this.loginModal = this.modalService.open(login, {});
  }

  close_login() {
    this.loginModal.close();
  }

  is_first_load() {
    if (sessionStorage.length == 0) {
      return true;
    }
    return false;
  }

  scrollTop(nav) {
    var scrollTop = document.querySelector(nav);
    scrollTop.scrollIntoView();
  }

  get_recent() {
    return sessionStorage.getItem("recent_tab");
  }

  p1(page,nav) {
    if (page == "first") {
      this.first = true;
    }
    else {
      this.first = false;
    }
    this.fullpage = true;
    var recent = sessionStorage.getItem("recent_tab");
    sessionStorage.clear();
    sessionStorage.setItem("recent_tab", page);
    if ( recent != page || nav == '') {
      this.scrollTop(".main-content-section");
    }
    else {
      this.scrollTop(nav);
    }
  }

  ipoSlideConfig = {
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    draggable: true,

    responsive: [,
      {
        breakpoint: 1170,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          dots: true,
          deagable: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          dots: true,
          deagable: true
        }
      },
      {
        breakpoint: 601,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          dots: true,
          deagable: true
        }
      },
    ]
  };


}
