<ng-template #login let-modal>
  <app-login (close_model)="close_login($event)"></app-login>
</ng-template>

<ng-template #register>
  <app-register (close_model)="close_register($event)"></app-register>
</ng-template>

<div id="container" class="main-content-section">
  <div id="#site-content" class="full-page">
    <div class="tab">
      <div class="sect-1">
        <div class="sec">
          <div class="dropdown">
            <button class="tab-button" (click)="p1('first','')">Homepage</button>
          </div>
          <div class="dropdown">
            <button class="btn tab-button" (click)="p1('second','')">
              Organisation
              <svg width="15" height="15" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path d="M3.81 4.38 8 8.57l4.19-4.19 1.52 1.53L8 11.62 2.29 5.91l1.52-1.53z" fill="#7A7A7A" />
              </svg>
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)="p1('second','')">Background </a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('second_1','')">Our Partners</a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('second_2','')">Our Team</a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('second_3','')">The Board</a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('second_4','')">Organisation Structure</a>
            </div>
          </div>
          <div class="dropdown">
            <button class="btn tab-button" (click)="p1('third','')">
              Memberships
            </button>
          </div>
          <div class="dropdown">
            <button class="tablinks" (click)="p1('fourth','')">Gallery</button>
          </div>
          <div class="dropdown1">
            <button class="btn tab-button" (click)="p1('fifth','')">
              Business Info
              <svg width="15" height="15" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path d="M3.81 4.38 8 8.57l4.19-4.19 1.52 1.53L8 11.62 2.29 5.91l1.52-1.53z" fill="#7A7A7A" />
              </svg>
            </button>
            <div class="dropdown-menu1">
              <div style="display:flex;flex-direction:row;">
                <a class="dropdown-item" (click)="p1('fifth_1','')">How to start your <br />Business</a>
                <div class="dropdown3">
                  <svg width="20" height="20" id="Layer_1" style="enable-background:new 0 0 512 512;margin-top:20px;" version="1.1" viewBox="0 0 512 512"  xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <polygon points="160,115.4 180.7,96 352,256 180.7,416 160,396.7 310.5,256 " fill="#7A7A7A"/>
                  </svg>
                  <div class="dropdown-menu3">
                    <a class="dropdown-item" (click)="p1('fifth_10','')">How to register a <br />Business</a>
                    <div class="solid"></div>
                    <a class="dropdown-item" (click)="p1('fifth_11','')">How to finance your <br />Business</a>
                    <div class="solid"></div>
                    <a class="dropdown-item" (click)="p1('fifth_12','')">How to market your <br />Business</a>
                    <div class="solid"></div>
                    <a class="dropdown-item" (click)="p1('fifth_13','')">How to manage your <br />Business</a>
                    <div class="solid"></div>
                    <a class="dropdown-item" (click)="p1('fifth_14','')">How to Franchise</a>
                  </div>
                </div>
              </div>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('fifth_2','')">Company Registrations</a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('fifth_3','')">SETA's</a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('fifth_4','')">BEE Verification</a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('fifth_5','')">Tendering</a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('fifth_6','')">SMME Development</a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('fifth_7','')">Funding</a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('fifth_8','')">Private Funding</a>
              <div class="solid"></div>
              <a class="dropdown-item" (click)="p1('fifth_9','')">Venture Capital Companies</a>
            </div>
          </div>
          <div class="dropdown">
            <button class="tablinks" (click)="p1('sixth','')">News</button>
          </div>
          <div class="dropdown2">
            <button class="btn tab-button">
              Industry Focus
              <svg width="15" height="15" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path d="M3.81 4.38 8 8.57l4.19-4.19 1.52 1.53L8 11.62 2.29 5.91l1.52-1.53z" fill="#7A7A7A" />
              </svg>
            </button>
            <div class="dropdown-menu2">
              <a class="dropdown-item" (click)="p1('seventh','')">Sectors </a>
              <div class="solid"></div>
            </div>
          </div>
          <div class="dropdown">
            <button class="tablinks" (click)="p1('eighth','')">Contact Us</button>
          </div>
        </div>   
      </div>   
    </div>

    <div class="page-body" *ngIf="fullpage">
      <!--P1 Homepage-->
      <div *ngIf="first || is_first_load() || get_recent() == 'first'">
        <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images3">
          <div ngxSlickItem *ngFor="let img of images3; index as i" class="grid-card">
            <div class="container-carousel">
              <img [src]="img" alt="" style="width:100%;height:350px;">
            </div>
          </div>
        </ngx-slick-carousel>

        <div class="container4 gap1">
          <div class="row">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">Homepage</h2>
                <h4 class="sub-heading"><strong>Creating sustainable business opportunities</strong></h4>
                <div class="row">
                  <div class="col-sm-12 para">
                    <p>The Western Cape Business Opportunities Forum (WECBOF) is widely recognised and respected as a powerful voice for business in the Western Cape; we are a provincial service organisation with our focus and attention firmly on the national and international business pulse.  We provide a platform for business to establish and maintain contact with fellow entrepreneurs; to have access to opportunities, information, and training; and to have representation on a number of relevant forums of government and other associations focussed on growing and enhancing the commercial sector, with a specific focus on SMMEs.</p>
                    <p>WECBOF has access to channels for information on how to grow and develop our members’ businesses, and is an enabler for our members to access new business opportunities.  We keep abreast of business, economic, and other relevant trends and forecasts for the benefit of our members, and we ensure that we are represented on the boards of relevant associations and affiliations in order to ensure that our members are well represented, and that opportunities are filtered to us for their benefit.</p>
                    <p>WECBOF therefore plays a critical role in the advancement of business in the Western Cape.</p>
                    <p>In order to serve our members optimally, we have re-focussed our strategic goals and objectives to be more relevant, more informed, and more inclusive.  Now more than ever, we are ready to serve you, our members.</p>
                    <p>At WECBOF we are particularly passionate about assisting with growing and developing businesses in the SMME sector; this sector is the backbone of any economy; it contributes to the country’s national product by either manufacturing goods of value, or through the provision of services to both consumers and/or other enterprises.  Importantly, SMMEs have the potential to create employment and upgrade human capital over time.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 centering">
              <div style="margin-top:20px;" class="row">
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        COVID19 News
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <a href="https://wecbof.co.za/20200424-loan-guarantee-national-treasury/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">Loan guarantee National Treasury</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/43308_12-5_tradeinduscompetition/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">Trade Industry Competition</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/43306_12-5_smallbusinessdev/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">Small Business Development</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/wiego_covid19_svs_whatssapp_sa-23-april/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">WIEGO_COVID19_SVs</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/tnpa_update_on_health_and_safety_measures_under_level_4_lockdown/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">TNPA Update on Health and Safety measures</span><br />
                    <span class="vt" style="padding-left:30px;">under Level 4 lockdown</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/covid19_export_impact_survey/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">COVID19_Export_Impact_survey</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/combined-nccc-proposal-on-risk-adjusted-approach-v5-5-002/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">Combined NCCC Proposal on Risk-Adjusted </span><br />
                    <span class="vt" style="padding-left:30px;">Approach v5</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/amendment-regs-3-16-april-clean-final-docx-1-docx-pdf/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">Amendment regs 3 16 April</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/10-ways-to-keep-your-corner-shop-covid-19-free-english/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">10 Ways to Keep Your Corner Shop COVID-19</span><br />
                    <span class="vt" style="padding-left:30px;">Free</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/seda-spaza-support-programme/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">Seda Spaza Support Programme</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/lockdownmeasures_16april2020/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">Lockdown Measures</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/guidelines-on-covid-relief-scheme-2/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">Guidelines on COVID Relief Scheme</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/dsbd-call-out-to-smmes-clothing-etc/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">DSBD call out to SMMEs clothing etc</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/4-support-for-informal-smmes-2/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">Support for informal SMMEs</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/20200407-sa-covid-19-relief-schemes-summaries-read-only/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">SA Covid-19 Relief Schemes</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/20200409_covid19_informal_trading_permit_spaza_shops_coa_business_licences/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">COVID19 Informal Trading Permit Spaza Shops</span><br />
                    <span class="vt" style="padding-left:30px;">COA Business Licences</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/covid-19-support-app-img-20200409-wa0013/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">COVID 19 Support App</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/covid_19-smme-support/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">COVID19 SMME Support</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/uif-covid-19/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt"> UIF (COVID-19)</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/guidelines-on-covid-relief-scheme/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">GUIDELINES FOR APPLICATION: DEBT RELIEF </span><br />
                    <span class="vt" style="padding-left:30px;">FINANCE SCHEM</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/faqs-4c/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">FAQs on Agriculture</span>
                  </a>
                  <br />
                  <a href="https://wecbof.co.za/dti-incentive-scheme-contact-info-pdf/">
                    <span class="vi"><i aria-hidden="true" class="far fa-caret-square-right"></i></span>
                    <span class="vt">DTI Incentive Scheme Contact Info</span>
                  </a>
                </div>
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Events Calender
                      </h3>
                    </div>
                  </div>
                </div>
                <div style="height:20px;"> </div>
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width: 60%;" class="centering">
                      <h3 class="head-text1">
                        Radio Interviews
                      </h3>
                    </div>
                    <div style="height:20px;"> </div>
                    <div class="elementor-element elementor-element-5cfd25c6 elementor-widget elementor-widget-audio" data-id="5cfd25c6" data-element_type="widget" data-widget_type="audio.default">
                      <div class="elementor-widget-container" >
                        <div class="elementor-soundcloud-wrapper" style="padding-left:30px;">
                          <iframe loading="lazy" height="450" frameborder="no" src="https://w.soundcloud.com/player/?visual=false&amp;url=https%3A%2F%2Fapi.soundcloud.com%2Fusers%2F227523738&amp;show_artwork=true&amp;maxheight=1000&amp;maxwidth=1200&amp;auto_play=false&amp;buying=true&amp;liking=true&amp;download=true&amp;sharing=true&amp;show_comments=true&amp;show_playcount=true&amp;show_user=true&amp;color"></iframe>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div style="height:20px;"> </div>
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Partners
                      </h3>
                    </div>
                    <div style="width:60%;">
                      <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images4">
                        <div ngxSlickItem *ngFor="let img of images4; index as i" class="grid-card">
                          <div class="container-carousel">
                            <img [src]="img" alt="" style="width:100%;height:300px;">
                          </div>
                        </div>
                      </ngx-slick-carousel>
                    </div>
                  </div>
                </div>
                <div style="height:30px;"> </div>
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Partners Links
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top:20px;" class="row">
            <div class="col-sm-12">
              <div class="col-sm-12 head-container">
                <div style="height: 40px; background-color: #31415e; width: 100%;">
                  <h3 class="head-text1">
                    How do I become a member?
                  </h3>
                </div>
                <div class="row" style="margin-top:20px;">
                  <div class="col-sm-12 para">
                    <p>You may contact us or download our digital membership application form. Members gain exclusive access to the latest training programmes, business information, and WECBOF news and events. Contact us today to sign up!</p>
                    <button class="btn more_info">
                      More Info
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--P2 Organisation & Background-->
      <div *ngIf="get_recent() == 'second'">
        <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images3">
          <div ngxSlickItem *ngFor="let img of images3; index as i" class="grid-card">
            <div class="container-carousel">
              <img [src]="img" alt="" style="width:100%;height:350px;">
            </div>
          </div>
        </ngx-slick-carousel>

        <div class="container4 gap1">
          <div class="row">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">Background</h2>
                <div class="row">
                  <div class="col-sm-6 para">
                    <p>Founded in 1995, the Western Cape Business Opportunities’ Forum (WECBOF) is a member-based organisation.  It was formed with the aim of empowering Black business in the Western Cape with relevant information, training and a networking platform to start, and grow, their businesses.</p>
                    <p>WECBOF is a voice and a resourceful network for entrepreneurs, corporates, and government to collaborate in an effort to build a stronger and more prosperous Western Cape economy.  In 2013, the WECBOF board decided to re-focus and re-design the structure of the business to ensure that it remains relevant, innovative, and progressive.</p>
                    <p>Currently, with so many organisations similar to WECBOF in the market, it makes sense to collaborate rather than work in isolation to serve a broader business community.</p>
                  </div>
                  <div class="col-sm-6 para">
                    <P>The premise of WECBOF’s new Organisational-Operational Structure 2013 – 2020 is one of lateral interaction, i.e. working collaboratively with other organisations to achieve a common objective. A modern approach to management and strategic approach and implementation has been adopted by WECBOF which is visionary, resourceful, and inclusive of all stakeholders for the advancement of the local economy.</P>
                    <P>With the launch of the new BEE Codes in May 2015, government has made a strong statement about its commitment to growing and developing entrepreneurs and small business.  WECBOF wishes to align itself with the requirements of these codes to ensure that it can advise its members adequately as to how these codes have to be implemented and monitored in the working environment.</P>
                    <P>Join us on this new and exciting journey; together we can achieve so much more…</P>
                  </div>
                </div>
                <hr />
                <h2 class="heading1">History</h2>
                <div class="row">
                  <div class="col-sm-6 para">
                    <p>Hope – the one word which summed up the feeling in the hearts of millions of disadvantaged people of South Africa on 27 April 1994.</p>
                    <p>The results of our country’s first democratic election sowed the seed for great expectations, but would legislative changes be enough to bear fruit for all in the longer term?  It became apparent that Black business leaders needed to play a more meaningful role in addressing the imbalances of the past by consolidating their efforts to nurture and develop economic growth.</p>
                    <p>It very quickly became clear that there were great imbalances in South Africa’s economy.  It was out of this imbalance and wealth disparities that WECBOF was founded. Questions were raised around the relevance of the organisation, given that there was already a number of Chambers of Commerce and formalised,successfully operating businesses, in the marketplace.</p>
                  </div>
                  <div class="col-sm-6 para">
                    <p>However, WECBOF fulfilled business requirements such as access to finance, training, and opportunities, which became its unique selling propositions at the time.</p>
                    <P>WECBOF was formed in January 1995 by local businesspeople, academics, and other prominent members of the local business community. Its formation was the result of months of planning and discussion around how previously disadvantaged small-and medium-enterprises could be meaningfully integrated into the economic landscape of the Western Cape Province, and operate as successful and sustainable enterprises.</P>
                    <P>WECBOF’s mission was established with its primary objective being to effectively contribute to the economic and social wellbeing of the province.</P>


                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 centering">
              <div style="margin-top:20px;" class="row">
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Memorandum of
                      </h3>
                    </div>
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Incorporation
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" style="margin-top:50px;margin-bottom:50px;">
                  <div class="row" style="display:flex;width:400px;">
                    <div style="width:100%;display:flex;flex-direction:row;">
                      <div class="para">Download M.O.I Below</div>
                    </div>
                  </div>
                  <div class="row" style="display:flex;width:400px;">
                    <div style="width:100%;display:flex;flex-direction:row;" class="centering">
                      <button class="btn download">
                        Download
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Our Objectives
                      </h3>
                    </div>
                    <div class="col-sm-12">
                      <div class="row" style="width:60%;">
                        <div class="col-sm-12 para">
                          <p><span class="organisation-head-text">• WECBOF</span> represents the interests of primarily the Black (African, Coloured, Indian, and Chinese) business community of the Western Cape.</p>
                          <p><span class="organisation-head-text">• WECBOF</span> strives to ensure that Black business is empowered with access to economic opportunities, access to finance, access to training, and access to business information.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="height:20px;"> </div>
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Mission Statement
                      </h3>

                    </div>
                    <div class="col-sm-12">
                      <div class="row" style="width:60%;">
                        <div class="col-sm-12 para">
                          <p>
                            <span class="organisation-head-text">WECBOF’s</span> mission is to contribute effectively to the economic and social development of the region inits area of interest by,
                          </p>
                          <p>
                            <span class="organisation-head-text">Co-ordinating</span> business relationships among its members, identifying business opportunities, facilitating the development of local capital and promoting the development of technology in the region;
                          </p>
                          <p>
                            <span class="organisation-head-text">Serving</span> as a supportive group to effect changes beneficial to Black business people and striving for the development of the disadvantaged communities
                            in the Western Cape assisting people with
                            professional skills desiring the establishment of
                            their own business;
                          </p>
                          <p>
                            <span class="organisation-head-text">Seeking</span> contacts and investment opportunities
                            from abroad and establishing relationships with
                            prospective business associates abroad; and
                          </p>
                          <p>
                            <span class="organisation-head-text">Developing</span> and propagating a strong code of
                            business conduct which will be guided by the
                            principles of non-racialism, non sexism and democracy,
                            and a sustained sense of social responsibility.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div style="margin-top:50px;">


          </div>
        </div>
      </div>
      <!--P2-2 Our Partners-->
      <div *ngIf="get_recent() == 'second_1'">
        <div class="container4 gap1">
          <div class="row" style="justify-content:start;">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">Our Partners</h2>
              </div>
            </div>

          </div>
          <div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div style="width:25%">
                  <img src="assets/home/H3.jpg" alt="" style="width: 200px; height: 200px; " />
                </div>
                <div style="width:25%">
                  <img src="assets/home/H1.jpg" alt="" style="width: 200px; height: 200px; " />
                </div>
                <div style="width:25%">
                  <img src="assets/home/H9.jpg" alt="" style="width: 200px; height: 200px; " />
                </div>
                <div style="width:25%">
                  <img src="assets/home/H2.jpg" alt="" style="width: 200px; height: 200px; " />
                </div>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div style="width:25%">
                  <img src="assets/home/H8.jpg" alt="" style="width: 200px; height: 200px; " />
                </div>
                <div style="width:25%">
                  <img src="assets/home/H4.jpg" alt="" style="width: 200px; height: 200px; " />
                </div>
                <div style="width:25%">
                  <img src="assets/home/H5.png" alt="" style="width: 200px; height: 200px; " />
                </div>
                <div style="width:25%">
                  <img src="assets/home/H7.jpg" alt="" style="width: 200px; height: 200px; " />
                </div>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12">
              <div>
                <div style="width:25%">
                  <img src="assets/home/H6.png" alt="" style="width: 200px; height: 200px; " />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--P2-3 Our Team-->
      <div *ngIf="get_recent() == 'second_2'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">Background</h2>
                <div style="width:25%">
                  <img src="assets/home/OT1.jpg" alt="" style="width: 400px; height: 600px; " />
                </div>
                <div class="row">
                  <div class="col-sm-12 para">
                    <p>After a short break from work, she took up the position of Marketing Manager at the Cape Chamber in 2001. Arifa’s energy is boundless; she not only facilitated the effective promotion of the Chamber’s membership promotion programme, managed a number of major events that have brought very positive recognition to the organisation, but she also increased the black membership of the organisation significantly. A Chamber that was hitherto accused of being too male and too white no longer has that stigma as most events are attended by a fair mixture of Black and White, male and female members, all networking effectively together. Furthermore, with her knowledge and experience of the past, a sharp knowledge of loss and recovery through hard work, as well as an intrinsic understanding of the struggling entrepreneur – desperate to get a break, seemingly no-hopers have been raised from self-pity to successful players in the market because she took the time to show them the way without being patronising or impatient.</p>
                    <p>After a short break from work, she took up the position of Marketing Manager at the Cape Chamber in 2001. Arifa’s energy is boundless; she not only facilitated the effective promotion of the Chamber’s membership promotion programme, managed a number of major events that have brought very positive recognition to the organisation, but she also increased the black membership of the organisation significantly. A Chamber that was hitherto accused of being too male and too white no longer has that stigma as most events are attended by a fair mixture of Black and White, male and female members, all networking effectively together. Furthermore, with her knowledge and experience of the past, a sharp knowledge of loss and recovery through hard work, as well as an intrinsic understanding of the struggling entrepreneur – desperate to get a break, seemingly no-hopers have been raised from self-pity to successful players in the market because she took the time to show them the way without being patronising or impatient.</p>
                    <p>Arifa left the Chamber in April 2012. In May 2012, Amaanah Business Consulting cc welcomed Arifa Parkar as a full partner. Using her vast talent and IP in the world of business and business networks, both locally and internationally, Arifa now specialises in business consulting for both SMMEs, as well as for more established organisations. She specialises in strategic workshops for businesses, helping them to map out timeframes for achieving their organisational and developmental goals. Arifa also specialises in helping communities form co-operatives and gives guidance in the registration, training, and mentoring of successful co-operatives. As a result of her vast knowledge and experience, she has successfully written training materials with a strong focus on networking and marketing and has now added negotiation- and deal-making skills to her training portfolio. In 2015, Arifa saw her media and interviewing talents coming to fruition; she managed to land a full year with Radio 786 with her own programme on ‘The Entrepreneurial Slot’. She is now busy writing a book on her talks since she feels that all the knowledge has to be shared.</p>

                  </div>
                </div>

              </div>
            </div>
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container para">
                <h3 class="organisation-head-text">
                  ARIFA PARKAR
                </h3>
                <p>Chief Executive Officer</p>
                <div class="row">
                  <div class="col-sm-12 para">
                    <p>Daughter of a shrewd but ethical businessman, Arifa was born in Zanzibar, grew up in the Seychelles, Mauritius, and Mumbai in India. She completed her formal education at the University of Bombay and Mumbai – with a BA in Economics and Politics as well as an LLB in 1985. The same year she acquired her qualifications in Business Management. Later she completed TEFL courses in 4 languages, attained a Diploma Elementaire de la Langue Francaise at Alliance Francaise, and a subsequent Marketing Management course in 2002 from the Cape Peninsula University of Technology in Cape Town with additional training in Management Development at University of Stellenbosch in 2006. In 1999 she joined the Department of Economic Affairs and Tourism where she was, inter alia, responsible for the Department’s Business Promotion Desk, an active problem solver (again) getting involved in many activities to promote business in the Western Cape and promoting partnerships with overseas markets with special focus on Italy. In 2012 and 2013 she successfully completed her training as a Facilitator and Assessor, and is now SETA Accredited.</p>
                    <p>Recently Arifa was given an award by Business Partners in recognition for her efforts and dedication toward the upliftment of SMMEs in the Western Cape. She has twice been nominated for the CEO Magazine’s Award Programme “Most Influential Women in South Africa”, and in 2012 she was the Finalist in the Professional Service Category, as well as the Business Women Association (BWA) Award Programme. Arifa is an active member and executive on the Committee of the South African Institute of International Affairs (Western Province Branch) and Director on the Board of Career Planet. Arifa was elected the 1st President of Mafubo South Africa, an international NGO.</p>
                    <p>In addition to her role as CEO of WECBOF, Arifa also owns and runs Aasha Investment Solutions (Pty) Ltd. Aasha is a full-service agency, focussed on providing business services in the form of HR support, investment advice, and mentoring and training to mainly the SMME sector.</p>

                  </div>
                </div>

              </div>
            </div>

          </div>
          <div style="margin-top:20px;" class="row">
            <div class="col-sm-12">
              <div class="col-sm-12 head-container">
                <hr />
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--P2-4 The Board-->
      <div *ngIf="get_recent() == 'second_3'">
        <div class="container4">
          <div class="row" style="justify-content:start;">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  The Board
                </h2>
              </div>
            </div>

          </div>
          <div style="height:30px;"></div>
          <div>
            <div class="col-sm-12">
              <div>
                <div style="width:33%">
                  <div>
                    <h6 class="elementor-heading-title">
                      ARIFA PARKAR
                    </h6>
                  </div>
                  <div>
                    <h6 class="elementor-heading-title2">
                      CHIEF EXECUTIVE OFFICER
                    </h6>
                  </div>
                  <div class="para">Call – <a href="#" class="tb">0710155638</a></div>
                  <div class="para">Email – <a href="#" class="tb">arifa@wecbof.co.za</a></div>
                </div>
              </div>
            </div>
            <div style="height:30px;"></div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div style="width:33%">
                  <div>
                    <h6 class="elementor-heading-title">
                      COLIN DARIES
                    </h6>
                  </div>
                  <div class="para">Call – <a href="#" class="tb">0828074381</a></div>
                  <div class="para">Email – <a href="#" class="tb">cdaries@nuera.co.za</a></div>
                </div>
                <div style="width:33%">
                  <div>
                    <h6 class="elementor-heading-title">
                      RUSSELL MILLER
                    </h6>
                  </div>
                  <div class="para">Call – <a href="#" class="tb">083 700 0441 </a></div>
                  <div class="para">Email – <a href="#" class="tb">russell@temo.co.za</a></div>
                </div>
                <div style="width:33%">
                  <div>
                    <h3 class="home-head-text">
                    </h3>
                    <h6 class="elementor-heading-title">
                      DR KARIEMA PRICE
                    </h6>
                  </div>
                  <div></div>
                  <div class="para">Email – <a href="#" class="tb"> kariema@pyramidsa.co.za</a></div>
                </div>
              </div>
            </div>
            <div style="height:30px;"></div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div style="width:33%">
                  <div>
                    <h6 class="elementor-heading-title">
                      HEINRICH JEMANE
                    </h6>
                  </div>
                  <div class="para">Call – <a href="#" class="tb">0824925856</a></div>
                  <div class="para">Email – <a href="#" class="tb">hmjemane@littlebizhub.co.za</a></div>
                </div>
                <div style="width:33%">
                  <div>
                    <h6 class="elementor-heading-title">
                      BRIAN SAYED
                    </h6>
                  </div>
                  <div class="para">Call – <a href="#" class="tb">0218527715</a></div>
                  <div class="para">Email – <a href="#" class="tb">brian@rbris.co.za</a></div>
                </div>
                <div style="width:33%">
                  <div>
                    <h6 class="elementor-heading-title">
                      GAVIN HUNTER
                    </h6>
                  </div>
                  <div class="para">Call – <a href="#" class="tb">0824149914</a></div>
                  <div class="para">Email – <a href="#" class="tb">hunter@boshard.co.za</a></div>
                </div>


              </div>
            </div>
            <div style="height:30px;"></div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div style="width:33%">
                  <div>
                    <h6 class="elementor-heading-title">
                      MARIAM DIEDERICKS
                    </h6>
                  </div>
                  <div class="para">Call – <a href="#" class="tb">0832774345</a></div>
                  <div class="para">Email – <a href="#" class="tb">mariam@coalitioncabling.co.za</a></div>
                </div>
                <div style="width:33%">
                  <div>
                    <h6 class="elementor-heading-title">
                      BRIAN WILLIAMS
                    </h6>
                  </div>
                  <div class="para">Call – <a href="#" class="tb">0825659390</a></div>
                  <div class="para">Email – <a href="#" class="tb">bkn@mweb.co.za</a></div>
                </div>
                <div style="width:33%">
                  <div>
                    <h6 class="elementor-heading-title">
                      RANEAH CARR
                    </h6>
                  </div>
                  <div class="para">Email – <a href="#" class="tb">rcarr@carranprojects.co.za</a></div>
                </div>


              </div>
            </div>
          </div>

        </div>
      </div>
      <!--P2-5 Organisation Structure-->
      <div *ngIf="get_recent() == 'second_4'">
        <div class="container4">
          <div class="row" style="justify-content:start;">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  Organisation Structure
                </h2>
              </div>
            </div>

          </div>
          <div style="height:20px;"></div>
          <div>
            <div class="col-sm-12 para">
              <div>
                <p>WECBOF is a non-profit organisation developed for the purpose of assisting businesses in the Western Cape to grow and to become sustainable.  It is managed by a board of directors and CEO, and has strong alliances with both the public and private sectors to ensure that it meets its objectives.</p>
              </div>
            </div>
            <div style="height:30px;"></div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div>
                  <img src="assets/home/OS1.png" alt="" style="width: 800px; height: 500px; " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P3 Memberships-->
      <div *ngIf="get_recent() == 'third'">
        <div class="container4">
          <div class="row" style="justify-content:start;">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  Memberships
                </h2>
              </div>
            </div>

          </div>
          <div style="height:20px;"></div>
          <div>
            <div class="col-sm-12 para">
              <div>
                <p>We have three categories of members: Corporate Business, Entrepreneurs, and Associates.  The Entrepreneur Category offers a number of value packages, subject to the size of your business.  We are currently updating the added value categories for each of the membership classifications; please make contact with our office (<a href="#" class="tb">office@wecbof.co.za</a>) for more information on these updated packages.  Below is an outline of the WECBOF membership criteria:</p>
              </div>
            </div>
            <div style="height:30px;"></div>
            <div class="row">
              <div class="column">
                <div class="card">
                  <div class="card-header">
                    <h2><b>Corporate Membership</b></h2>
                  </div>
                  <div class="card-content">
                    <div class="card-sub">Costs:</div>
                    <ul>
                      <li>R10,000 per annum</li>
                    </ul>
                    <div class="card-sub">Member Benefits:</div>
                    <ul>
                      <li>Free entry for two company representatives to Dinner & Dialogue. </li>
                      <li>Free entry for two company representatives to bio-monthly Breakfast Bites Network forums.</li>
                      <li>Exclusive invitations to international business-to-business delegations to establish trade/industrial connections.</li>
                      <li>Exclusive invitations to meetings of Government to establish trade/industrial connections.</li>
                    </ul>
                  </div>               
                </div>
              </div>

              <div class="column">
                <div class="card">                  
                  <div class="card-header">
                    <h2><b>Entreprenuers</b></h2>
                  </div>
                  <div class="card-content">
                    <div class="card-sub">Costs:</div>
                    <ul>
                      <li>Gold: R5,000 per annum (Membership is voluntary)</li>
                      <li>Silver: R2,500 per annum (Membership is voluntary)</li>
                      <li>Blue: R1,200 per annum</li>
                      <li>Youth: R350 per annum</li>
                    </ul>
                    <div class="card-sub">Member Benefits:</div>
                    <ul>
                      <li>Free entry for one company representative to Dinner & Dialogue. </li>
                      <li>Free entry for one company representative to bio-monthly Breakfast Bites Network forums</li>
                      <li>Free entry for one company representative to selected stakeholder forums to establish trade/industrial connections.</li>
                      <li>Inclusion on mailing list for monthly newsletter </li>
                      <li>Inclusion on mailing list for tender opportunities</li>                     
                    </ul>
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="card">
                  <div class="card-header">
                    <h2><b>Associates & NGO's</b></h2>
                  </div>               
                  <div class="card-content">
                    <div class="card-sub">Costs:</div>
                    <ul>
                      <li>R750 per annum</li>
                    </ul>
                    <div class="card-sub">Member Benefits:</div>
                    <ul>
                      <li>Inclusion on mailing list for monthly newsletter </li>
                      <li>Inclusion on mailing list for tender opportunities</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <hr />
            </div>
          </div>
        </div>
      </div>
      <!--P4  Gallery-->
      <div *ngIf="get_recent() == 'fourth'">
        <div class="container4">
          <div class="row" style="justify-content:start;">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  Gallery
                </h2>
              </div>
            </div>
          </div>
          <div style="height:10px;"></div>
          <div class="col-sm-12">
            <hr />
          </div>
        </div>
      </div>
      <!--P5  Business Info-->
      <div *ngIf="get_recent() == 'fifth'">
        <div class="container4">
          <div class="row" style="justify-content:start;">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  Business Assistance
                </h2>
              </div>
            </div>
          </div>
          <div style="height:20px;"></div>
          <div>
            <div class="col-sm-12 para">
              <div>
                <p>
                  WECBOF is essentially an agent which assists with growing and sustaining already established businesses.
                </p>
                <p>Start-up businesses, on the other hand, initially require a different set of procedures to follow to get its operations off the ground.  WECBOF provides assistance in this regard by putting would-be entrepreneurs in touch with the relevant organisations to assist start-ups.</p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12">
              <div class="row" style="justify-content:start;">
                <div class="col-sm-6 centering">
                  <div class="col-sm-12 head-container">
                    <h4 class="sub-heading">
                      <b>Masisizane Fund, an Old Mutual Initiative</b>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 para">
              <div>
                <p>The Masisizane Fund, an Old Mutual initiative, offers tailored integrated and flexible financial and non-financial solutions including financial education, capacity development, and mentoring support to entrepreneurs/small businesses.  The Fund takes time to fully understand each enterprise’s needs, challenges and characteristics.  This understanding, and the partnership which is created between the Fund and the entrepreneur/small business, adds value and offers innovative enterprise models aimed at ensuring long-term enterprise growth, sustainability and development impact.  The Masisizane Fund invests in SMMEs which have registered businesses, clear marketable products or services, and which create and retain jobs.  The Fund provides loan financing in productive sectors which have high employment and growth multipliers with a strong rural, township, and peri-urban bias.  Sectors include:  </p>
              </div>
              <ul>
                <li>Agri-business (primary & agri-processing)</li>
                <li>Franchising & commercial</li>
                <li>Supply chain & manufacturing</li>
              </ul>
              <div>
                <p>
                  Non-financial value adding services include:
                </p>
              </div>
              <ul>
                <li>
                  Capacity development – business management and technical support
                </li>
                <li>
                  Financial education
                </li>
                <li>
                  Market development
                </li>
                <li>
                  Product/service quality standards and compliance
                </li>
              </ul>
              <div>
                <p>
                  A business must show financial feasibility and the ability to repay a loan.
                </p>
                <p>Website access for more information: <a href="http://dogreatthings.co.za/masisizane/" class="tb">http://dogreatthings.co.za/masisizane/</a></p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div>
                  <img src="assets/home/BI1.jpg" alt="" style="width: 600px; height: 320px; " />
                </div>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="row" style="justify-content:start;">
              <div class="col-sm-6 centering">
                <div class="col-sm-12 head-container">
                  <h4 class="sub-heading">
                    <b>Small Enterprise Develop Agency</b>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-sm-12 para">
              <div>
                <p>The Small Enterprise Development Agency (SEDA) is an agency of the Department of Small Business Development.  SEDA was established in December 2004 through the National Small Business Amendment Act, Act 29 of 2004.  It is mandated to implement government’s small business strategy; design and implement a standard and common national delivery network for small enterprise development; and integrate government-funded small enterprise support agencies across all tiers of government.  SEDA’s mission is to develop, support and promote small enterprises throughout the country, ensuring their growth and sustainability in co-ordination and partnership with various role-players, including global partners, who make international best practices available to local entrepreneurs.</p>
                <p>Website access for more information: <a href="http://www.seda.org.za/Pages/Home.aspx" class="tb">http://www.seda.org.za/Pages/Home.aspx</a></p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div>
                  <img src="assets/home/BI2.png" alt="" style="width: 225px; height: 83px; " />
                </div>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="row" style="justify-content:start;">
              <div class="col-sm-6 centering">
                <div class="col-sm-12 head-container">
                  <h4 class="sub-heading">
                    <b>Funding</b>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-sm-12 para">
              <div>
                <p>The DTi has identified and prioritised entrepreneurship as a catalyst and means to achieving economic growth and development for South Africa as a whole.  To make this happen, the DTi, in conjunction with other government departments and institutions, is implementing and executing a number of schemes and policies to ensure that businesses have access to the necessary <span><a class="tb" href="#">finance and non-financial support</a> </span> to make their ventures sustainable and successful.  The DTi has a range of funding schemes available to new and existing businesses, ranging from micro businesses to co-operatives to medium-sized businesses. Financial assistance from the DTi can take the form of cost-sharing incentives, loans or grants, and mentorship programmes.</p>
                <p>
                  Website access for more information: <a href="https://www.thedti.gov.za/financial_assistance/financial_incentives.jsp" class="tb">https://www.thedti.gov.za/financial_assistance/financial_incentives.jsp</a>
                </p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div>
                  <img src="assets/home/BI3.png" alt="" style="width: 225px; height: 83px; " />
                </div>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="row" style="justify-content:start;">
              <div class="col-sm-6 centering">
                <div class="col-sm-12 head-container">
                  <h4 class="sub-heading">
                    <b>Black Umbrella</b>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-sm-12 para">
              <div>
                <p>Black Umbrellas is a non-profit enterprise development incubation organisation partnering with the private sector, government, and civil society to address the low levels of entrepreneurship and high failure rate of 100% Black-owned emerging businesses in South Africa.  The programme focuses on promoting entrepreneurship as a desirable economic path, and nurturing 100% Black-owned businesses in the critical first 3 years of their existence through the provision of nationwide incubators.</p>
                <p>Website access for more information: <a href="http://www.blackumbrellas.org/" class="tb">http://www.blackumbrellas.org/</a></p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div>
                  <img src="assets/home/BI4.jpg" alt="" style="width: 369px; height: 154px; " />
                </div>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="row" style="justify-content:start;">
              <div class="col-sm-6 centering">
                <div class="col-sm-12 head-container">
                  <h4 class="sub-heading">
                    <b>Productivity SA</b>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-sm-12 para">
              <div>
                <p>Productivity SA is established in terms of section 31 (1) of the Employment Services Act, No. 4 of 2014 as a juristic person with a mandate to promote employment growth and productivity thereby contributing to South Africa’s socio economic development and competitiveness. Productivity SA is managed in accordance with the Public Finance Management Act (PFMA).</p>
                <p>Website access for more information: <a href="https://productivitysa.co.za/" class="tb">https://productivitysa.co.za/</a></p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div>
                  <img src="assets/home/BI5.png" alt="" style="width: 580px; height: 164px; " />
                </div>
              </div>
            </div>





          </div>
        </div>
      </div>
      <!--P5_1  How to start your Business-->
      <div *ngIf="get_recent() == 'fifth_1'">
        <div class="container4">
          <div class="row" style="justify-content:start;">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  How to start your Business
                </h2>
              </div>
            </div>
          </div>
          <div style="height:10px;"></div>
          <div>
            <div class="col-sm-12 para">
              <div>
                <h4 class="sub-heading">
                  <b>Choose an option that is right for you</b>
                </h4>
                <p>When you start your own business choose the one that will go well with your lifestyle if possible one in which you have some direct understanding and skills. For example if you want to work regular hours opting for a fast food business is not a good idea and also it is important to know a little bit about what is the business involve, even if you are not a expert yourself.</p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12 para">
              <div>
                <h4 class="sub-heading">
                  <b>Do your market research</b> 
                </h4>
                <p>Market research does not have to be difficult but it is important to make sure that there is a need for your product or service, find out if there is a competition already established in your area and whether you are offering is unique or fills a gap in the market.</p>
                <p>
                  If you can offer a product or service people need which does not already exist you will have a good chance of success for example a health snack where there is a demand but no supply.
                </p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12 para">
              <div>
                <h4 class="sub-heading">
                  <b>Draw up a business plan</b>
                </h4>
                <p>Writing a business plan is like sketching a road map you are not possible to reach your goal unless you know where you are going and what you will need to get there. You need about three to four pages that will give the business operational and financial direction.</p>
                <p>For more info: <a href="http://www.entrepreneur.com/article/247575" class="tb">http://www.entrepreneur.com/article/247575</a></p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12 para">
              <div>
                <h4 class="sub-heading">
                  <b>Finanancing your business</b>
                </h4>
                <p>Your business would need capital or cashflow to sustain your business activities. You could finance it yourself, get a business loan from a private banking institution, apply for a government funding schemes or seek an investment partner.</p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12 para">
              <div>
                <h4 class="sub-heading">
                  <b>Employ the right people</b>
                </h4>
                <p>In a small business people spend a lot of time together so you need to have employees that you believe you will be able to work with and choose people who have experience in a business that you be starting that will give you a valuable support system.</p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12 para">
              <div>
                <h4 class="sub-heading">
                  <b>Market your business</b>
                </h4>
                <p>Business does not come to those who wait it comes to those who go out there and tell people what they have to offer. Even small businesses need to market its services for example to place ads in local newspapers, putting up notices in shopping centres, post in social media and billboards if you can afford that. The best marketing tool for business is word of mouth.</p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12 para">
              <div>
                <h4 class="sub-heading">
                  <b>Put financial system in place</b>
                </h4>
                <p>Even if you are not a financial expert yourself   implement a simple but solid financial systems right from the start. There are excellent, user friendly software package that you can to do this and it is worth contracting the services of a bookkeeper or other financial professional to assist you on a regular basis.     </p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12 para">
              <div>
                <h4 class="sub-heading">
                  <b>Manage your cash flow</b>
                </h4>
                <p>Cash is the life blood of a business and it is important to manage cash flow carefully, the right investor or financial consultant will be able to help you do this.</p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12 para">
              <div>
                <h4 class="sub-heading">
                  <b>Supplement your own knowledge with specialist skills</b>
                </h4>
                <p>It is important to constantly build on your skills in all aspects of your business, read newspapers and magazines, research and attend training short courses and workshops on a consistent basis to implement the most productive processes in your business and keep abreast of industry trends.</p>
              </div>
            </div>
            <div style="height:10px;"></div>
            <div class="col-sm-12 para">
              <div>
                <h4 class="sub-heading">
                  <b>Enjoy yourself</b>
                </h4>
                <div>
                  <p>There will be good and bad days but it is important to enjoy what you are doing and try to maintain some kind of work and life balance.</p>
                  <p>Source:</p>
                </div>
                <ul>
                  <li><a href=" http://southafrica.smetoolkit.org/sa/en/content/en/3523/Ten-simple-rules-for-a-successful-start-up" class="tb">http://southafrica.smetoolkit.org/sa/en/content/en/3523/Ten-simple-rules-for-a-successful-start-up</a></li>
                  <li><a href="www.businesspartners.co.za" class="tb">www.businesspartners.co.za</a></li>
                  <li><a href="https://www.sba.gov/content/follow-these-steps-starting-business" class="tb">https://www.sba.gov/content/follow-these-steps-starting-business</a></li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--P5_2  Company Registrations-->
      <div *ngIf="get_recent() == 'fifth_2'">
        <div class="container4">
          <div class="row" style="justify-content:start;">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  Company Registrations
                </h2>
              </div>
            </div>
          </div>
          <div>
            <div class="col-sm-12 para">
              <div>
                <ul>
                  <li>CIPC – Companies and Intellectual Property Commission</li>
                </ul>
                <p>Services:</p>
                <ul>
                  <li>Registration of companies,</li>
                  <li>Registration of companies,</li>
                  <li>Intellectual Property Rights (trademarks, patents, designs, and copyright)</li>
                </ul>
                <p>Website: <a href="www.cipc.co.za" class="tb">www.cipc.co.za</a></p>

              </div>
            </div>
            <div class="col-sm-12">
              <div class="our_partners1">
                <div>
                  <img src="assets/home/CR1.jpg" alt="" style="width: 300px; height: 250px; " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P5_3  SETA's-->
      <div *ngIf="get_recent() == 'fifth_3'">
        <div class="container4">
          <div class="row" style="justify-content:start;">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  SETA's
                </h2>
              </div>
            </div>
          </div>
          <div>
            <div class="col-sm-12 para">
              <div>
                <p>SETAs (Sector Education and Training Authorities) were developed:</p>
                <ul>
                  <li>
                    By government to address the many skills and development needs.
                  </li>
                  <li>
                    In order to plan and manage training effectively. The economy is divided into 23 sectors, each with their own SETA.
                  </li>
                  <li>
                    To equip young people with the necessary skills development and training for starting their first jobs, but also for those who are already in jobs to develop and enhance their skills.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P5_4  BEE Verification-->
      <div *ngIf="get_recent() == 'fifth_4'">
        <div class="container4 gap1">
          <div class="row">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container para">
                <h2 class="heading1">BEE Verification Agencies</h2>
                <p>A business needs a BEE-rating certificate to prove their level of BEE status; the certificate includes the business revenue and the level of black ownership.</p>
                <p>For background, intention & application of the Codes of Good Practice</p>
                <p>A BEE certificate for Exempted Micro Enterprise can be obtained in the following BEE verification agencies from the charge of R450.0 to R1 410.00 for the Exempted Micro Enterprise (EME).</p>
                <hr />
                <h3 class="home-head-text">Beg Commercial Consulting (Pty) Ltd <br /> (BEGCC)</h3>
                <p>Service:</p>
                <ul>
                  <li>BBBEE</li>
                  <li>Employment Equity</li>
                  <li>Skills Development</li>
                  <li>EME Certificate</li>
                </ul>
                <p>Website: <a href="www.begcc.co.za" class="tb">www.begcc.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV1.png" alt="" style="width: 347px; height: 107px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">Abacus Verification (Pty) Ltd</h2>
                <p>Service</p>
                <ul>
                  <li>BEE Rating across all industries</li>
                  <li>BEE Scenario Planning</li>
                  <li>Free Tender Search</li>
                </ul>
                <p>Website: <a href="www.bee-verification.co.za" class="tb">www.bee-verification.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV2.jpg" alt="" style="width: 298px; height: 82px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">AQRATE (Pty) Ltd</h2>
                <p>Service:</p>
                <ul>
                  <li>BEE Codes of Good Practice</li>
                  <li>B-BBEE Status</li>
                  <li>B-BBEE Certificate</li>
                </ul>
                <p>Website: <a href="www.aqrate.co.za" class="tb">www.aqrate.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV3.jpg" alt="" style="width: 600px; height: 186px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">BBBEE Rating Agency</h2>
                <p>Service:</p>
                <ul>
                  <li>BBBEE Codes of Good Practice</li>
                </ul>
                <p>Website: <a href="www.bbbee.com" class="tb">www.bbbee.com</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV4.jpg" alt="" style="width: 489px; height: 103px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">BEE Empowered and Labour <br />Consultancy</h2>
                <p>Service:</p>
                <ul>
                  <li>BBBEE Codes of Good Practice</li>
                </ul>
                <p>Website: <a href="www.bempowered.net" class="tb">www.bempowered.net</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV5.png" alt="" style="width: 228px; height: 88px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">BEE Online (Pty) Ltd</h2>
                <p>Service:</p>
                <ul>
                  <li>BBBEE Assessments & Monitoring</li>
                  <li>BBBEE Supplier Analysis& Advisory</li>
                  <li>BBBEE Training</li>
                </ul>
                <p>Website: <a href="www.beeonline.co.za" class="tb">www.beeonline.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV6.png" alt="" style="width: 385px; height: 131px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">BEE Verification Agency</h2>
                <p>Service:</p>
                <ul>
                  <li>BEE verification audits</li>
                </ul>
                <p>Website: <a href="www.beever.co.za" class="tb">www.beever.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV7.png" alt="" style="width: 359px; height: 80px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">Codex Ratings (Pty) Ltd</h2>
                <p>Service:</p>
                <ul>
                  <li>Evaluation & Verification, Consultation & Strategy Planning</li>
                </ul>
                <p>Website: <a href="www.codexratings.co.za" class="tb">www.codexratings.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV8.jpg" alt="" style="width: 211px; height: 200px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">Empowerdex CTN (Pty) Ltd</h2>
                <p>Service:</p>
                <ul>
                  <li>BBBEE Codes of Good Practice</li>
                  <li>Pre-verification support.</li>
                </ul>
                <p>Website: <a href="www.empowerdex.com" class="tb">www.empowerdex.com</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV9.png" alt="" style="width: 432px; height: 117px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">Mindwalk Consultancy (Pty) Ltd</h2>
                <p>Service:</p>
                <ul>
                  <li>Verification, Workshops & Presentations</li>
                  <li>Research Servicse</li>
                  <li>Legislatives & Compliance Insight</li>
                </ul>
                <p>Website: <a href="www.mindwalk.co.za" class="tb">www.mindwalk.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV10.png" alt="" style="width: 218px; height: 43px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">SERA WC (Pty) Ltd</h2>
                <p>Service:</p>
                <ul>
                  <li>BBBEE Codes of Good Practice and Exempted Micro Enterprises</li>
                  <li>Qualified Small Enterprises and Generic Enterprises Verification</li>
                </ul>
                <p>Website: <a href="www.serabv.com" class="tb">www.serabv.com</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV11.png" alt="" style="width: 84px; height: 84px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">Ukuthenga Verification Solutions</h2>
                <p>Service:</p>
                <ul>
                  <li>BEE Verification</li>
                  <li>The BEE Codes of Good Practice</li>
                </ul>
                <p>Website: <a href="www.ukuthenga-vs.co.za" class="tb">www.ukuthenga-vs.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV12.jpg" alt="" style="width: 279px; height: 177px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">IQUAD Verification Services (Pty) Ltd</h2>
                <p>Service:</p>
                <ul>
                  <li>Compliance Audits</li>
                  <li>Evaluations and Verifications</li>
                  <li>Scorecards</li>
                  <li>Consulting and Strategy Planning and Training Programmes</li>
                </ul>
                <p>Website: <a href="www.iquadvs.co.za" class="tb">www.iquadvs.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV13.jpg" alt="" style="width: 240px; height: 92px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">Mosela Rating Agency</h2>
                <p>Service:</p>
                <ul>
                  <li>BBBEE Codes of Good Practice</li>
                </ul>
                <p>Website: <a href="www.moselarating.co.zaa" class="tb">www.moselarating.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV14.jpg" alt="" style="width: 457px; height: 110px; " />
                  </div>
                </div>
                <hr />
                <h2 class="heading1">Kbonga BEE Verification Agency</h2>
                <p>Service:</p>
                <ul>
                  <li>Evaluation, Verification, BBBEE Certificate.</li>
                  <li>Supplier BEE Status.</li>
                </ul>
                <p>Website: <a href="www.kbee.co.za" class="tb">www.kbee.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/BV15.png" alt="" style="width: 152px; height: 51px; " />
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <div class="col-sm-6 centering">
              <div style="margin-top:20px;" class="row">
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Memorandum of
                      </h3>
                    </div>
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Incorporation
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" style="margin-top:50px;margin-bottom:50px;">
                  <div class="row" style="display:flex;width:400px;">
                    <div style="width:100%;display:flex;flex-direction:row;">
                      <div class="para">Download M.O.I Below</div>
                    </div>
                  </div>
                  <div class="row" style="display:flex;width:400px;">
                    <div style="width:100%;display:flex;flex-direction:row;" class="centering">
                      <button class="btn download">
                        Download
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Our Objectives
                      </h3>

                    </div>
                    <div class="col-sm-12">
                      <div class="row" style="width:60%;">
                        <div class="col-sm-12 para">
                          <p><span class="organisation-head-text">• WECBOF</span> represents the interests of primarily the Black (African, Coloured, Indian, and Chinese) business community of the Western Cape.</p>
                          <p><span class="organisation-head-text">• WECBOF</span> strives to ensure that Black business is empowered with access to economic opportunities, access to finance, access to training, and access to business information.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="height:20px;"> </div>
                <div class="col-sm-12">
                  <div class="col-sm-12 head-container">
                    <div style="height: 40px; background-color: #31415e; width:60%;" class="centering">
                      <h3 class="head-text1">
                        Mission Statement
                      </h3>

                    </div>
                    <div class="col-sm-12">
                      <div class="row" style="width:60%;">
                        <div class="col-sm-12 para">
                          <p>
                            <span class="organisation-head-text">WECBOF’s</span> mission is to contribute effectively to the economic and social development of the region inits area of interest by,
                          </p>
                          <p>
                            <span class="organisation-head-text">Co-ordinating</span> business relationships among its members, identifying business opportunities, facilitating the development of local capital and promoting the development of technology in the region;
                          </p>
                          <p>
                            <span class="organisation-head-text">Serving</span> as a supportive group to effect changes beneficial to Black business people and striving for the development of the disadvantaged communities
                            in the Western Cape assisting people with
                            professional skills desiring the establishment of
                            their own business;
                          </p>
                          <p>
                            <span class="organisation-head-text">Seeking</span> contacts and investment opportunities
                            from abroad and establishing relationships with
                            prospective business associates abroad; and
                          </p>
                          <p>
                            <span class="organisation-head-text">Developing</span> and propagating a strong code of
                            business conduct which will be guided by the
                            principles of non-racialism, non sexism and democracy,
                            and a sustained sense of social responsibility.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P5_5 Tendering-->
      <div *ngIf="get_recent() == 'fifth_5'">
        <div class="container4">
          <div class="row" style="justify-content:start;">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  Tendering
                </h2>
              </div>
             
            </div>
          </div>

        </div>
      </div>
      <!--P5_6  SMME Development-->
      <div *ngIf="get_recent() == 'fifth_6'">
        <div class="container4">
          <div class="row" style="justify-content:start">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container para">
                <h2 class="heading1">
                  SMME Development
                </h2>
                <ul>
                  <li class="organisation-head-text">Business Partners</li>
                </ul>
                <p>Service:</p>
                <ul>
                  <li>Business counseling is a proven practice, worldwide, whereby business problems are diagnosed, expertise shared, solutions sought and guidance given.</li>
                  <li>Consultants and Mentors provide on-going counseling and support, based on experience, knowledge, skills and wisdom</li>
                </ul>
                <p>Website: <a href="www.businesspartners.co.za" class="tb">www.businesspartners.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/SD1.png" alt="" style="width: 128px; height: 128px; " />
                  </div>
                </div>
                <ul>
                  <li class="organisation-head-text">Fetola</li>
                </ul>
                <p>Service:</p>
                <ul>
                  <li>Strategic consulting services, access to mentors and personalised business support. Access to news, views, tips, tools & information.</li>
                  <li>Business skills training, including leadership development.</li>
                </ul>
                <p>Website: <a href="www.fetola.co.za" class="tb">www.fetola.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/SD2.png" alt="" style="width: 420px; height: 120px; " />
                  </div>
                </div>
                <ul>
                  <li class="organisation-head-text">The Western Cape Funding Fair</li>
                </ul>
                <p>Service:</p>
                <ul>
                  <li>Business skills training, including leadership development.the Western Cape Funding Fair is to support and promote entrepreneurship and SME business in order to start and sustain business projects which create and retain jobs for the economic development of the Western Cape .</li>
                </ul>
                <p>Website: <a href="www.westerncapefundingfair.co.za" class="tb">www.westerncapefundingfair.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/SD3.jpg" alt="" style="width: 236px; height: 68px; " />
                  </div>
                </div>
                <ul>
                  <li class="organisation-head-text">University of Western Cape</li>
                </ul>
                <p>Service:</p>
                <ul>
                  <li>The Entrepreneurship Development Unit builds the capacity of existing enterprises to take advantages of business opportunities.</li>
                  <li>
                    Enables students and business owners to manage enterprises more effectively and efficiently by providing training and counselling support/ consulting services.
                  </li>
                  <li>
                    Works in partnership with government and non-government organisations that are strategically placed to promote economic growth and enterprise development, particularly in the Western Cape.
                  </li>
                </ul>
                <p>Website: <a href="www.uwc.ac.za" class="tb">www.uwc.ac.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/SD4.png" alt="" style="width: 205px; height: 246px; " />
                  </div>
                </div>







              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P5_7 Funding-->
      <div *ngIf="get_recent() == 'fifth_7'">
        <div class="container4">
          <div class="row" style="justify-content:start">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container para" >
                <h2 class="heading1">
                  Goverment Funding
                </h2>
                <ul>
                  <li class="organisation-head-text">National Empowerment Funding (NEF)</li>
                </ul>
                <p>Service:</p>
                <ul>
                  <li>Funding advice</li>
                  <li>Business Planning</li>
                  <li>General Assistance</li>
                </ul>
                <p>Website: <a href="www.nefcorp.co.za" class="tb">www.nefcorp.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/GF1.jpg" alt="" style="width: 210px; height: 83px; " />
                  </div>
                </div>
                <ul>
                  <li class="organisation-head-text">Small Enteprise Finance Agency (SEFA)</li>
                </ul>
                <p>Service:</p>
                <ul>
                  <li>
                    SEFA Direct Lending Products – loans provided directly to small- and medium-sized enterprises as well as co-operatives operating in all sectors of the economy.
                  </li>
                  <li>
                    SEFA Wholesale Lending Products – providing facilities to intermediaries, joint venture partnerships and other collaborative relationships
                  </li>
                </ul>
                <p>Website: <a href="www.sefa.org.za" class="tb">www.sefa.org.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/GF2.png" alt="" style="width: 300px; height: 134px; " />
                  </div>
                </div>
                <ul>
                  <li class="organisation-head-text">Industrial Development Corporation  (IDC)</li>
                </ul>
                <p>Service:</p>
                <ul>
                  <li>Providing finance for industrial projects</li>
                  <li>Promoting partnerships between and across industries within South Africa as well as across its borders</li>
                </ul>
                <p>Website: <a href="www.idc.co.zaa" class="tb">www.idc.co.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/GF3.jpg" alt="" style="width: 250px; height: 91px; " />
                  </div>
                </div>
                <ul>
                  <li class="organisation-head-text"> Department of Trade and Industry</li>
                </ul>
                <p>Service:</p>
                <ul>
                  <li>Support Programme</li>
                  <li>Funding</li>
                </ul>
                <p>Website: <a href=" www.dit.gov.za" class="tb"> www.dit.gov.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/GF4.png" alt="" style="width: 225px; height: 83px; " />
                  </div>
                </div>
                <ul>
                  <li class="organisation-head-text">National Youth Development Agency(NYDA)</li>
                </ul>
                <p>Service:</p>
                <ul>
                  <li>The NYDA plays a lead role in ensuring government and private sector and civil society make youth development a priority by identifying and implementing solutions to address youth development challenges.</li>
                </ul>
                <p>Website: <a href="www.nyda.gov.za" class="tb">www.nyda.gov.za</a></p>
                <div style="height:10px;"></div>
                <div class="our_partners1">
                  <div>
                    <img src="assets/home/GF5.jpg" alt="" style="width: 355px; height: 206px; " />
                  </div>
                </div>






              </div>
            </div>
          </div>

        </div>
      </div>
      <!--P5_8  Private Funding-->
      <div *ngIf="get_recent() == 'fifth_8'">
        <div class="container4">
          <div class="row" style="justify-content:start">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  Private Funding
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P5_9 Venture Capital Companies-->
      <div *ngIf="get_recent() == 'fifth_9'">
        <div class="container4 gap1">
          <div class="row">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container para">
                <h2 class="heading1">Venture Capital Companies</h2>
                <h4 class="organisation-head-text">Angelhubventures</h4>
                <p>Services:</p>
                <ul>
                  <li>We look for strong leadership, disruptive business models & scalable businesses poised for rapid growth.</li>
                  <li>We invest in businesses with a minimum viable product, customer traction, viable business model & an executable business plan.</li>
                  <li>We provide support in key areas, access to our networks, strategic guidance, operational support & funding.</li>
                </ul>
                <p>Website: <a href="www.angelhubventures.com" class="tb">www.angelhubventures.com</a></p>

                <h4 class="organisation-head-text">Invenfin</h4>
                <p>Services:</p>
                <ul>
                  <li>Big focus on innovation</li>
                  <li>Early Stage Capital</li>
                  <li>Supporting skills and networks as well as strategic advice.</li>
                </ul>
                <p>Website: <a href="www.invenfin.com" class="tb">www.invenfin.com</a></p>

                <h4 class="organisation-head-text">EchoVC</h4>
                <p>Services:</p>
                <ul>
                  <li>EchoVC Partners is a seed and early stage venture capital firm focused on financing and cross-pollinating leading technologies, teams, business models and knowledge across North America, Africa and SE Asia.</li>
                </ul>
                <p>Website: <a href="www.echovc.com" class="tb">www.echovc.com</a></p>

                <h4 class="organisation-head-text">I/O Ventures</h4>
                <p>Services:</p>
                <ul>
                  <li>I/O ventures is an early stage startup program that focuses heavily on mentorship. We work closely with founders from product launch through the next stage of company development, sharing what has proven to work for product scaling, revenue growth and fund raising</li>
                </ul>
                <p>Website: <a href="www.ventures.io" class="tb">www.ventures.io</a></p>

                <h4 class="organisation-head-text">88mph</h4>
                <p>Services:</p>
                <ul>
                  <li>88mph do investments in early stage mobile or web companies targeting the African market. Focusing purely on ideas that have the potential to scale across Africa</li>
                </ul>
                <p>Website: <a href="www.88mph.ac" class="tb">www.88mph.ac</a></p>

                <h4 class="organisation-head-text">eVentures Africa Fund</h4>
                <p>Services:</p>
                <ul>
                  <li> Internet and/or mobile applications, platforms, e-commerce and solution-providers (i.e. not in infrastructure and hardware)</li>
                </ul>
                <p>Website: <a href="www.eva-fund.com" class="tb">www.eva-fund.com</a></p>

                <h4 class="organisation-head-text">Africa Media Venture Fund</h4>
                <p>Services:</p>
                <ul>
                  <li>Africa Media Ventures Fund mobilizes capital and experience in the Netherlands to invest in entrepreneurs in the media sector and small and medium sized African media companies. We offer a combination of venture capital and business development support, involving knowledge, experience, access to proven business concepts/applications and network. Our capital investment will range from EUR 15,000 to EUR 100,000.</li>
                </ul>
                <p>
                  Website: <a href="www.amvf.nl" class="tb">www.amvf.nl</a>

                  <h4 class="organisation-head-text">KnifeCap</h4>
                <p>Services:</p>
                <ul>
                  <li>Knife Capital is an independent third party fund manager focusing on high growth, high impact technology-enabled businesses from South Africa and other frontier economies across Sub-Saharan Afric</li>
                </ul>
                <p>
                  Website: <a href="www.knifecap.com" class="tb">www.knifecap.com</a>

                  <h4 class="organisation-head-text">Jacana</h4>
                <p>Services:</p>
                <ul>
                  <li>Jacana Partners is a pan-African private equity company that invests in entrepreneurs, builds successful small-to-medium sized enterprises (SMEs) and delivers sustainable financial and social returns.</li>
                </ul>
                <p>
                  Website: <a href="www.jacanapartners.com" class="tb">www.jacanapartners.com</a>

                  <h4 class="organisation-head-text">4Di</h4>
                <p>Services:</p>
                <ul>
                  <li>They Offer: Capital, access to global networks and resources, and a strategic mentoring and partnership approach. Sectors of interest: Promising technology, software, web, media and telecommunications prospects. Funding Stage: Full life-cycle, from seed and early stage through to growth and expansion financing</li>
                </ul>
                <p>
                  Website: <a href="http://www.4dicapital.com/" class="tb">http://www.4dicapital.com/</a>

                  <h4 class="organisation-head-text">Hasso Plattnerf Venture Africa</h4>
                <p>Services:</p>
                <ul>
                  <li>Global Gateway via Hasso Plattner Ventures Europe. They Offer more than just capital – provides a network of experienced entrepreneurs, partners, industry networks and third party service providers.</li>
                </ul>
                <p>
                  Website: <a href="http://hp-ventures.com/" class="tb">http://hp-ventures.com/</a>

                  <h4 class="organisation-head-text">Industrial Development Corporation </h4>
                <p>Services:</p>
                <ul>
                  <li>
                    They Offer: Support of ideas not addressed by the market and serving as a catalyst for sustainable market growth.
                  </li>
                  <li>
                    Funding Stage: Early to development stage
                  </li>
                </ul>
                <p>
                  Website: <a href="www.amvf.nl" class="tb">www.amvf.nl</a>

                  <h4 class="organisation-head-text">ISLabs</h4>
                <p>Services:</p>
                <ul>
                  <li>
                    They Offer: Business and technical mentorship, hosting internet access facilities and incubator level funding.
                  </li>
                  <li>
                    Funding Stage: Early
                  </li>
                </ul>
                <p>
                  Website: <a href="www.islabs.co.za" class="tb">www.islabs.co.za</a>

                  <h4 class="organisation-head-text">EdgeGrowth (AKA Vumela)</h4>
                <p>Services:</p>
                <ul>
                  <li>
                    This a fairly new VC Fund from First Rand that is based in Johannesburg, but provides funding nation-wide.
                  </li>
                  <li>
                    They also provide strong incubation support. (The Fund Manager of Vumela is FNB in Alliance with Edge Growth, with Edge providing the expertise and human resources for the actual investment activity.)
                  </li>
                </ul>
                <p>
                  Website: <a href="http://www.edgegrowth.com/" class="tb">http://www.edgegrowth.com/</a>

                  <h4 class="organisation-head-text">Industrial Development Corporation</h4>
                <p>Services:</p>
                <ul>
                  <li>
                    Investments that fall below other companies’ mandate. They focus on deals below R100 million and because this is relatively competitor free are able to negotiate favourable prices with target companies.
                  </li>
                  <li>
                    Funding Stage: Growth and expansion
                  </li>
                </ul>
                <p>
                  Website: <a href="http://acornequity.com/" class="tb">http://acornequity.com/</a>

                  <h4 class="organisation-head-text">Intel Capital</h4>
                <p>Services:</p>
                <ul>
                  <li>
                    Vast resources, including insights into roadmaps, engineering methods, and access to their labs.
                  </li>
                  <li>
                    Funding Stage:  Early finance to growth and merger
                  </li>
                </ul>
                <p>
                  Website: <a href="www.intelcapital.com" class="tb">www.intelcapital.com</a>

                  <h4 class="organisation-head-text">Trium Investment (TriVest)</h4>
                <p>Services:</p>
                <ul>
                  <li>
                    TriVest is a leading provider of equity for growth capital financings, middle market corporate acquisitions and recapitalizations.
                  </li>
                  <li>
                    TriVest prefers to invest in quality small to medium sized companies, which possess or promise strong and defensible market positions in growing or fragmented industries.
                  </li>
                  <li>
                    TriVest pursues investment opportunities which are based in South Africa, but with the potential to achieve scale through expansion within Africa.
                  </li>
                </ul>
                <p>Website: <a href="http://www.trivest.co.za/" class="tb">http://www.trivest.co.za/</a>
              </div>
            </div>
            <div class="col-sm-6 centering">
              <div>
                <div>
                  <img src="assets/home/VCC1.png" alt="" style="width: 300px; height: 49px; " />
                </div>
                <div style="height:200px;"></div>
                <div>
                  <img src="assets/home/VCC2.png" alt="" style="width: 318px; height: 60px; " />
                </div>
                <div style="height:100px;"></div>
                <div>
                  <img src="assets/home/VCC3.png" alt="" style="width: 671px; height: 68px; " />
                </div>
                <div style="height:100px;"></div>
                <div>
                  <img src="assets/home/VCC4.png" alt="" style="width: 153px; height: 44px; " />
                </div>
                <div style="height:150px;"></div>
                <div>
                  <img src="assets/home/VCC5.png" alt="" style="width: 180px; height: 122px; " />
                </div>
                <div style="height:50px;"></div>
                <div>
                  <img src="assets/home/VCC6.png" alt="" style="width: 750px; height: 95px; " />
                </div>
                <div style="height:150px;"></div>
                <div>
                  <img src="assets/home/VCC7.png" alt="" style="width: 150px; height: 70px; " />
                </div>
                <div style="height:100px;"></div>
                <div>
                  <img src="assets/home/VCC8.png" alt="" style="width: 345px; height: 85px; " />
                </div>
                <div style="height:50px;"></div>
                <div>
                  <img src="assets/home/VCC9.png" alt="" style="width: 300px; height: 105px; " />
                </div>
                <div style="height:50px;"></div>
                <div>
                  <img src="assets/home/VCC10.jpg" alt="" style="width: 256px; height: 60px; " />
                </div>
                <div style="height:150px;"></div>
                <div>
                  <img src="assets/home/VCC11.jpg" alt="" style="width: 100px; height: 121px; " />
                </div>
                <div style="height:150px;"></div>
                <div>
                  <img src="assets/home/VCC12.jpg" alt="" style="width: 250px; height: 91px; " />
                </div>
                <div style="height:50px;"></div>
                <div>
                  <img src="assets/home/VCC13.png" alt="" style="width: 168px; height: 93px; " />
                </div>
                <div style="height:100px;"></div>
                <div>
                  <img src="assets/home/VCC14.png" alt="" style="width: 144px; height: 105px; " />
                </div>
                <div style="height:80px;"></div>
                <div>
                  <img src="assets/home/VCC15.png" alt="" style="width: 382px; height:83px; " />
                </div>
                <div style="height:80px;"></div>
                <div>
                  <img src="assets/home/VCC16.png" alt="" style="width: 100px; height: 100px; " />
                </div>
                <div style="height:80px;"></div>
                <div>
                  <img src="assets/home/VCC17.jpg" alt="" style="width: 426px; height: 174px; " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P5_10 How to register a Business-->
      <div *ngIf="get_recent() == 'fifth_10'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-12 centering">
              <div class="col-sm-12 head-container para">
                <h2 class="heading1">
                  How to register a Business
                </h2>
                <p>A company may register with or without a company name, when a company is registered without reserved name; its registration number will automatically becomes the company name. This is a quickest way to register a company</p>
                <p>Such a company may transact with a trading (business) name, or may apply to add a reserved name at a later stage. In this case, the company will need to first reserve a name and then apply for a name change, which constitutes a change to its <a href="#"class="tb">Memorandum of Incorporation</a> (MOI) (refer to  Maintain a Business).</p>
                <p>If your initial name reservation application is not approved, you will need to apply for new names. You may apply for between 1 and 4 names during each application process. Each name reservation application costs R50. A company registration may vary between R125 and R475 (R125 for a private company, R475 for a non-profit company registered without members).</p>
                <p>There are five types of companies that you can register.  If you wish to run a franchise business, you would register a private company.  If you wish to register a church, you would register a non-profit company.  A private school could be registered as a private company or non-profit company, depending on its objectives.  An association of professionals such as lawyers, doctors, civil engineers etc, may be registered as a personal liability company.</p>
                <p>Source: <a href="www.cipc.co.za" class="tb">www.cipc.co.za</a></p>
                <p>For more info <a href="http://www.cipc.co.za/index.php/register-your-business/companies/" class="tb">http://www.cipc.co.za/index.php/register-your-business/companies/</a></p>
                <p><a href="http://www.cipc.co.za/files/2413/9452/7679/CompaniesAct71_2008.pdf" class="tb">http://www.cipc.co.za/files/2413/9452/7679/CompaniesAct71_2008.pdf</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P5_11 How to finance your Business-->
      <div *ngIf="get_recent() == 'fifth_11'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-12 centering">
              <div class="col-sm-12 head-container para">
                <h2 class="heading1">
                  How to finance your Business
                </h2>
                <p>For start-up and existing business owners there are two questions that one should ask themselves before borrowing money from friends and family or making loan to financial institutes.</p>
                <ul>
                  <li>
                    How much money do you need? If you do not know exactly how much money you need, then no-one is going to lend anything to you. And nor should they, because it means that you have not thought through your business plan properly.
                  </li>
                  <li>
                    What do you need the money for? Rough estimates do not go down well when applying for finance. You need to be specific as to what you are going to buy, when you are going to buy it, what you are going to use it for, and how much money you are going to create through your purchase. Also, if you know exactly what you need the money for, you can think of ways of managing without borrowing. For example, if you need money for a particular machine, do you necessarily have to buy your own, or could you share the cost with a partner (or even a competitor)? If you need money to purchase extra stock, will your suppliers be able to help you out in the short term?
                  </li>
                </ul>
                <p>Source: <a href="www.seda.org.za" class="tb">www.seda.org.za</a></p>
                <p>Sourced  and for addition information on how to finance your business visit these links</p>
                <ul>                
                  <li><a href="http://www.seda.org.za/MyBusiness/Factsheets/Pages/WheredoIfindfinance.aspx" class="tb">http://www.seda.org.za/MyBusiness/Factsheets/Pages/WheredoIfindfinance.aspx</a></li>
                  <li><a href="http://www.forbes.com/sites/martinzwilling/2013/03/06/10-more-creative-ways-to-finance-your-startup/" class="tb">http://www.forbes.com/sites/martinzwilling/2013/03/06/10-more-creative-ways-to-finance-your-startup/</a></li>
                  <li><a href="www.businessowner.co.za" class="tb">www.businessowner.co.za</a></li>
                </ul>


              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P5_12 How to market your Business-->
      <div *ngIf="get_recent() == 'fifth_12'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-12 centering">
              <div class="col-sm-12 head-container para">
                <h2 class="heading1">
                  How to market your Business
                </h2>
                <ul>
                  <li>Marketing is a process of communicating the value of a product or a service to a customer.</li>
                </ul>
                <p>There are four basic aspects of marketing called the “4P’s”:</p>
                <ul>
                  <li>
                    Product: the item or service you sell
                  </li>
                  <li>
                    Price: the amount you charge for your product or service
                  </li>
                  <li>
                    Promote: the ways you inform your market as to who, what and where you are
                  </li>
                  <li>
                    Place: the channels you use to take the product to the customer
                  </li>
                </ul>
                <p> Be a savvy social networker create business accounts and participate in the big social media sites – Facebook, Twitter, LinkedIn, Google+, and Pinterest. Add Instagram in there too if your business is image-oriented.</p>
                <p>Source: <a href="www.seda.org.za" class="tb">www.seda.org.za</a></p>
                <p>For more information on how to market your business in four steps. <a href="http://www.entrepreneur.com/article/241607" class="tb">http://www.entrepreneur.com/article/241607</a></p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P5_13 How to manage your Business-->
      <div *ngIf="get_recent() == 'fifth_13'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-12 centering">
              <div class="col-sm-12 head-container para">
                <h2 class="heading1">
                  How to manage your Business
                </h2>
                <ul>
                  <li>Once your business is up and running, you really need to be organised if you want to become profitable. This means having systems in place that you can manage easily and effectively. A system is just a set way of doing things, so that every demand that is thrown at you can be ‘channelled’ in a certain direction and dealt with in a planned way.</li>
                </ul>
                <p>Proper systems will help you:</p>
                <ul>
                  <li>
                    Keep track of what your business is doing.
                  </li>
                  <li>
                    Keep calm about what needs to be done.
                  </li>
                  <li>
                    Monitor how well you are getting things done.
                  </li>
                  <li>
                    Improve your ability to do things right the first time.
                  </li>
                </ul>
                <p>You also need proper systems to keep your business legal especially if you employ people.</p>
                <p>Source: <a href="www.seda.org.za" class="tb">www.seda.org.za</a></p>
                <p>Sourced and for additional info visit: <a href="http://www.seda.org.za/MyBusiness/SEDAStart/Pages/Managing.aspx" class="tb">http://www.seda.org.za/MyBusiness/SEDAStart/Pages/Managing.aspx</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P5_14 How to Franchise-->
      <div *ngIf="get_recent() == 'fifth_14'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-12 centering">
              <div class="col-sm-12 head-container para">
                <h2 class="heading1">
                  How to Franchise
                </h2>
                <ul>
                  <li>Franchise is the agreement or license between two legally independent parties which gives:</li>
                </ul>
                <ul>
                  <li>
                    A person or group of people (franchisee) the right to market a product or service using the trademark or trade name of another business (franchisor)
                  </li>
                  <li>
                    The franchisee the right to market a product or service
                  </li>
                </ul>
                <p>A prospective franchisee should always undertake research that includes and is not limited to:</p>
                <ul>
                  <li>
                    Annual financial statements and/or management accounts
                  </li>
                  <li>
                    A list of stock
                  </li>
                  <li>
                    Valuation of assets to be purchased
                  </li>
                  <li>
                    Staff audit
                  </li>
                  <li>
                    Contingent liabilities of the existing concern
                  </li>
                  <li>
                    Transferring concern to a new legal entity
                  </li>
                  <li>
                    Checking customer base and suppliers with existing contracts.
                  </li>
                </ul>
                <p>Source: <a href="www.trade.gov" class="tb">www.trade.gov</a></p>
                <p><a href="http://www.trade.gov/sabit/static/Introduction%20to%20Franchising-IFA-Eng_Latest_tg_sabit_005404.pdf" class="tb">http://www.trade.gov/sabit/static/Introduction%20to%20Franchising-IFA-Eng_Latest_tg_sabit_005404.pdf</a></p>






              </div>
            </div>
          </div>
        </div>
      </div>
      <!--P6 News-->
      <div *ngIf="get_recent() == 'sixth'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-6 centering" style="width:33%;">
              <div>
                <img src="assets/home/N1.jpg" alt="" style="width: 450px; height: 350px; " />
              </div>
            </div>
            <div class="col-sm-6 centering" style="width:33%">
              <div>
                <img src="assets/home/N2.jpg" alt="" style="width: 450px; height: 350px; " />
              </div>
            </div>
            <div class="col-sm-6 centering" style="width:33%">
              <div>
                <img src="assets/home/N3.jpg" alt="" style="width: 450px; height: 350px; " />
              </div>
            </div>
          </div>
          <div style="height:20px;"></div>
          <div class="row">
            <div class="col-sm-6 centering">
              <h2 class="heading1">WECBOF e-bulletins</h2>
            </div>
          </div>
          <div style="padding-left:80px;">
            <div class="col-sm-6 centering">
              <div style="display:flex;flex-direction:row;">
                <div style="padding-right:20px;">
                  <button class="btn news" style="width:300px;">
                    WECBOF e-bulletin December 2022
                  </button>
                </div>
                <div style="padding-right:20px;">
                  <button class="btn news" style="width:300px;">
                    WECBOF e-bulletin August 2022
                  </button>
                </div>
                <div>
                  <button class="btn news" style="width:300px;">
                    WECBOF e-bulletin May 2022
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">Announcements</h2>
                <div>
                  <img src="assets/home/N4.png" alt="" style="width: 553px; height: 1024px; " />
                </div>
                <h2 class="heading1">Upcoming Events</h2>
                <div style="height:50px;"></div>
                <h2 class="heading1">Applications</h2>
              </div>
            </div>
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">Information</h2>
                <div>
                  <img src="assets/home/N5.png" alt="" style="width: 553px; height: 1024px; " />
                </div>
                <div style="height:10px;"></div>
                <div>
                  <img src="assets/home/N6.jpg" alt="" style="width: 553px; height: 281px; " />
                </div>
                <p><a href="#" class="tb">WCG FAQs for workplace vaccination plans 2022</a></p>
                <p>STATE OF THE NATION ADDRESS BY PRESIDENT CYRIL <br />RAMAPHOSA. CAPE TOWN CITY HALL</p>
                <p>10 February 2022. PDF link below:</p>
                <p><a href="#" class="tb">STATE OF THE NATION ADDRESS – PDF</a></p>
                <div>
                  <img src="assets/home/N7.jpg" alt="" style="width: 553px; height: 576px; " />
                </div>
                <p><a href="#" class="tb">NT_response_to_public_hearings_on_the_2021_MTBPS </a></p>
                <div style="height:30px;"></div>
                <div>
                  <img src="assets/home/N8.jpg" alt="" style="width: 553px; height: 576px; " />
                </div>
              </div>
            </div>
          </div>
          <div style="height:20px;"></div>
          <div class="row">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">Our Partners & Supporters</h2>
                <div class="row">
                  <div class="col-sm-6 centering">
                    <img src="assets/home/H1.jpg" alt="" style="width: 100px; height: 100px; " />
                    <img src="assets/home/H3.jpg" alt="" style="width: 100px; height: 100px; " />
                    <img src="assets/home/H6.jpg" alt="" style="width: 100px; height: 100px; " />
                    <img src="assets/home/H2.jpg" alt="" style="width: 100px; height: 100px; " />
                    <img src="assets/home/H9.jpg" alt="" style="width: 100px; height: 100px; " />
                    <img src="assets/home/H8.jpg" alt="" style="width: 100px; height: 100px; " />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 centering">
                    <img src="assets/home/H5.png" alt="" style="width: 100px; height: 100px; " />
                    <img src="assets/home/H7.jpg" alt="" style="width: 100px; height: 100px; " />
                    <img src="assets/home/H4.jpg" alt="" style="width: 100px; height: 100px; " />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <!--P7 Sectors-->
      <div *ngIf="get_recent() == 'seventh'">
        <div class="container4">
          <div class="row" style="justify-content:start">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container">
                <h2 class="heading1">
                  Sectors
                </h2>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!--P8 Contact-Us-->
      <div *ngIf="get_recent() == 'eighth'">
        <div class="container4">
          <div class="row" style="justify-content:start">
            <div class="col-sm-6 centering">
              <div class="col-sm-12 head-container para">
                <h2 class="heading1">
                  Contact Us
                </h2>
                <h4 class="organisation-head-text">
                  Western Cape Business Opportunities Forum
                </h4>
                <p>Physical Address: Nu Era</p>
                <p>3 Irene Street</p>
                <p>Bellville, 7530</p>
                <p> South Africa</p>
                <div class="row">
                  <div class="col" style="width:20%">
                    <p>Postal Address</p>
                    <p>Telephone</p>
                    <p>E-mail</p>
                    <p>Chief Executive Officer</p>
                    <p>Administration</p>
                  </div>
                  <div class="col" style="width:20%">
                    <p>: P.O.Box 707, Kasselsvlei 7533, South Africa</p>
                    <p>: +27 (21) 946 2519</p>
                    <p>: General Enquiries: <a href="info@wecbof.co.za" class="tb">info@wecbof.co.za</a></p>
                    <p>: <a href="arifa@wecbof.co.za" class="tb">arifa@wecbof.co.za</a></p>
                    <p>: <a href="office@wecbof.co.za" class="tb">office@wecbof.co.za</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top:20px;">
            <div class="col-sm-12">
              <button class="btn download">
                Click Me
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- .container -->
  </div><!-- #main -->
</div>

<div class="footer">
  <div class="col-sm-12">
    <div class="col-sm-12 footer-cont1">
      <div style="width:20%;">
        <h2 class="head-text2">
          Contact Us
        </h2>
        <div>
          icon +27 (21) 946 2519
        </div>
        <div>
          icon +27 (21) 9516853
        </div>
        <div>
          icon office@wecbof.co.za
        </div>
      </div>
      <div style="width:20%;">
        <h2 class="head-text2">
          Directions
        </h2>
        <div class="elementor-element elementor-element-5c3e6270 elementor-widget elementor-widget-google_maps" data-id="5c3e6270" data-element_type="widget" data-widget_type="google_maps.default">
          <div class="elementor-widget-container">
            <div class="elementor-custom-embed">
              <iframe width="200" height="200" loading="lazy" src="https://maps.google.com/maps?q=The%20address%20is%20no.%203%20Irene%20street%20bellville&amp;t=m&amp;z=17&amp;output=embed&amp;iwloc=near" title="The address is no. 3 Irene street bellville" aria-label="The address is no. 3 Irene street bellville"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div style="width:20%;">
        <h2 class="head-text2">
          Gallery
        </h2>
        <div style="width: 90%;">
          <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images5">
            <div ngxSlickItem *ngFor="let img of images5; index as i" class="grid-card">
              <div class="container-carousel">
                <img [src]="img" alt="" style="width:100%;height:150px;">
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
      <div style="width:20%;">
        <h2 class="head-text2">
         Follow Us On <br /> Facebook
        </h2>
      </div>      
    </div>
  </div>
  <div class="col-sm-12">
    <div class="col-sm-12 footer-cont2">
      <div style="color: grey; font-size: 15px">
        Website by <a href="#">Temo</a>
      </div>
      <div style="color:grey;font-size:15px;">
        Copyright 2015, Wecbof – Western Cape Business Opportunities Forum
      </div>     
    </div>
  </div>

</div><!-- #container -->
