<header class="p-x bg-white text-black">
  <nav class="navbar navbar-expand-md navbar">
    <div class="nav-container">
      <div class="nav-container-container1">
        <a [routerLink]="['/']">
          <img src="../../assets/nav-menu/Logos/NEW-LOGO.png" alt="Responsive image" class="img-fluid-logo" />
        </a>
        <div class="nav-container-container-container" style="margin-right:350px;">
          <a style="color:#7A7A7A;font-size: 13px;">
            Western Cape Business Opportunities Forum
          </a>
        </div>
        <div class="nav-container-container-containerr">
          <div style="width:110px;height:50px;">
            <button class="btn Login" (click)="openLogin(login)">Login</button>
          </div>
          <div style="width:110px;height:50px;">
            <button class="btn Register" (click)="openRegister(register)">Register</button>
          </div>
        </div>
        <!--<div class="right">
    <button class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-label="Toggle navigation"
            [attr.aria-expanded]="isExpanded">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>-->
      </div>   
    </div>
  </nav>

  <ng-template #login let-modal>
    <app-login (close_model)="close_login($event)"></app-login>
  </ng-template>

  <ng-template #register>
    <app-register (close_model)="close_register($event)"></app-register>
  </ng-template>

  <ng-template #reset_password>
    <app-password-reset (close)="close_reset_password($event)" (completed)="reset_password_complete($event)"></app-password-reset>
  </ng-template>
</header>
